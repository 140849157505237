import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

// lazy load all the views

//s201
const S201FileUpload = React.lazy(() => import('../atons/s201/Add/Add'));

//profile
const AtoNProfile = React.lazy(() => import('../atons/profile'));

// dashboard
// const Dashboard = React.lazy(() => import('../atons/dashboard'));

//equipment
const EquipmentList = React.lazy(() => import('../atons/equipment/List'));
const EquipmentShow = React.lazy(() => import('../atons/equipment/Show'));
const EquipmentAdd = React.lazy(() => import('../atons/equipment/Add'));
const EquipmentModify = React.lazy(() => import('../atons/equipment/Modify'));

//structure
const StructureList = React.lazy(() => import('../atons/structure/List'));
const StructureShow = React.lazy(() => import('../atons/structure/Show'));
const StructureAdd = React.lazy(() => import('../atons/structure/Add'));
const StructureModify = React.lazy(() => import('../atons/structure/Modify'));

//mark
const MarkMap = React.lazy(() => import('../atons/mark/Map'));
const MarkList = React.lazy(() => import('../atons/mark/List'));
const MarkShow = React.lazy(() => import('../atons/mark/Show'));
const MarkAdd = React.lazy(() => import('../atons/mark/Add'));
const MarkModify = React.lazy(() => import('../atons/mark/Modify'));

//proposal
const ProposalList = React.lazy(() => import('../atons/proposal/List'));
const ProposalShow = React.lazy(() => import('../atons/proposal/Show'));

//Proposal - member
const ProposalMemberList = React.lazy(() => import('../atons/proposal/member/List'));
const ProposalMemberShow = React.lazy(() => import('../atons/proposal/member/Show'));

//Proposal - info
const ProposalInfoMain = React.lazy(() => import('../atons/proposal/info/Main'));
const ProposalInfoEmergencyList = React.lazy(() => import('../atons/proposal/info/Emergency/List'));

const ProposalInfoStandByList = React.lazy(() => import('../atons/proposal/info/StandBy/List'));
const ProposalInfoStandByShow = React.lazy(() => import('../atons/proposal/info/StandBy/Show'));

const ProposalInfoScheduleList = React.lazy(() => import('../atons/proposal/info/Schedule/List'));
const ProposalInfoScheduleShow = React.lazy(() => import('../atons/proposal/info/Schedule/Show'));

const ProposalInfoProcessList = React.lazy(() => import('../atons/proposal/info/Process/List'));
const ProposalInfoProcessShow = React.lazy(() => import('../atons/proposal/info/Process/Show'));

const ProposalInfoCompletionList = React.lazy(() => import('../atons/proposal/info/Completion/List'));
const ProposalInfoCompletionShow = React.lazy(() => import('../atons/proposal/info/Completion/Show'));

const ProposalInfoEntireList = React.lazy(() => import('../atons/proposal/info/Entire/List'));
const ProposalInfoEntireShow = React.lazy(() => import('../atons/proposal/info/Entire/Show'));

//operation
const OperationList = React.lazy(() => import('../atons/operation/manager/List'));
const OperationShow = React.lazy(() => import('../atons/operation/manager/Show'));
const OperationAdd = React.lazy(() => import('../atons/operation/manager/Add'));
const OperationModify = React.lazy(() => import('../atons/operation/manager/Modify'));
const OperationCompleteList = React.lazy(() => import('../atons/operation/complete/List'));
const OperationCompleteShow = React.lazy(() => import('../atons/operation/complete/Show'));
const OperationStatistics = React.lazy(() => import('../atons/operation/statistics'));
const OperationPlanList = React.lazy(() => import('../atons/operation/plan/List'));
const OperationPlanShow = React.lazy(() => import('../atons/operation/plan/Show'));
const OperationPlanAdd = React.lazy(() => import('../atons/operation/plan/Add'));
const OperationPlanModify = React.lazy(() => import('../atons/operation/plan/Modify'));

//maintenance
const MaintenanceList = React.lazy(() => import('../atons/maintenance/manager/List'));
const MaintenanceShow = React.lazy(() => import('../atons/maintenance/manager/Show'));
const MaintenanceAdd = React.lazy(() => import('../atons/maintenance/manager/Add'));
const MaintenanceModify = React.lazy(() => import('../atons/maintenance/manager/Modify'));
const MaintenanceCompleteList = React.lazy(() => import('../atons/maintenance/complete/List'));
const MaintenanceCompleteShow = React.lazy(() => import('../atons/maintenance/complete/Show'));
const MaintenanceStatistics = React.lazy(() => import('../atons/maintenance/statistics'));
const MaintenancePlanList = React.lazy(() => import('../atons/maintenance/plan/List'));
const MaintenancePlanEdit = React.lazy(() => import('../atons/maintenance/plan/Add'));

//repair-equipment
const RepairEquipmentList = React.lazy(() => import('../atons/repair/equipment/List'));
const RepairEquipmentModify = React.lazy(() => import('../atons/repair/equipment/Modify'));
const RepairEquipmentShow = React.lazy(() => import('../atons/repair/equipment/Show'));

//repair-structure
const RepairStructureList = React.lazy(() => import('../atons/repair/structure/List'));
const RepairStructureModify = React.lazy(() => import('../atons/repair/structure/Modify'));
const RepairStructureShow = React.lazy(() => import('../atons/repair/structure/Show'));

//accident
const AccidentList = React.lazy(() => import('../atons/accident/List'));
const AccidentAdd = React.lazy(() => import('../atons/accident/Add'));
const AccidentShow = React.lazy(() => import('../atons/accident/Show'));
const AccidentModify = React.lazy(() => import('../atons/accident/Modify'));

//construction
const ConstructionList = React.lazy(() => import('../atons/privateinfo/construction/List'));
const ConstructionAdd = React.lazy(() => import('../atons/privateinfo/construction/Add'));
const ConstructionShow = React.lazy(() => import('../atons/privateinfo/construction/Show'));
const ConstructionModify = React.lazy(() => import('../atons/privateinfo/construction/Modify'));

//statistics - Equipment
const StatisticsEquipmentSuppliesManagementStatus = React.lazy(() => import('../atons/statistics/equipment/equipmentSuppliesManagementStatus'));
const StatisticsEquipmentInstallationAndOperationStatus = React.lazy(() => import('../atons/statistics/equipment/equipmentInstallationAndOperationStatus'));
const StatisticsEquipmentStatusByMark = React.lazy(() => import('../atons/statistics/equipment/equipmentStatusByMark'));
const StatisticsStatusOfInspectionTargetEquipmentAndSupplies = React.lazy(() => import('../atons/statistics/equipment/statusofInspectionTargetEquipmentandSupplies'));

//statistics - Mark
const StatisticsMarkGeneralStatusList = React.lazy(() => import('../atons/statistics/markGeneralStatus'));
const StatisticsMarkClassStatusList = React.lazy(() => import('../atons/statistics/markClassStatus'));
const StatisticsMarkPurposeStatusList = React.lazy(() => import('../atons/statistics/markPurposeStatus'));
const StatisticsMarkSpecificPurposeStatusList1 = React.lazy(() => import('../atons/statistics/markSpecificPurposestatus1'));
const StatisticsMarkSpecificPurposeStatusList2 = React.lazy(() => import('../atons/statistics/markSpecificPurposeStatus2'));
const StatisticsStatusOfPublicAndPrivateMark = React.lazy(() => import('../atons/statistics/mark/statusOfPublicAndPrivateMark'));
const StatisticsStatusOfPrivateMark = React.lazy(() => import('../atons/statistics/mark/statusOfPrivateMark'));
const StatisticsStatusOfFacilityAndRegistrationOutsourcedManagement = React.lazy(() => import('../atons/statistics/mark/statusOfFacilityAndRegistrationOutsourcedManagement'));

//statistics - Buoy
const StatisticsCurrentStatusOfOffshoreBuoyDeployment = React.lazy(() => import('../atons/statistics/buoy/currentStatusOfOffshoreBuoyDeployment'));
const StatisticsInventoryStatusOfBuoy = React.lazy(() => import('../atons/statistics/buoy/InventoryStatusOfBuoy'));
const StatisticsInstallationAndInventoryStatusOfBuoy = React.lazy(() => import('../atons/statistics/buoy/InstallationAndInventoryStatusOfBuoy'));
const StatisticsAnnualBuoyStatus = React.lazy(() => import('../atons/statistics/buoy/annualBuoyStatus'));
const StatisticsAnnualBuoyMaintenanceStatus = React.lazy(() => import('../atons/statistics/buoy/annualBuoyMaintenanceStatus'));
const StatisticsMonthlyBuoyMaintenanceShipStatus = React.lazy(() => import('../atons/statistics/buoy/monthlyBuoyMaintenanceShipStatus'));
const StatisticsDetailedStatusOfAdditionalBuoyWork = React.lazy(() => import('../atons/statistics/buoy/detailedStatusOfAdditionalBuoyWork'));
const StatisticsMaintenancePlanForTheBuoyMaintenanceVessel = React.lazy(() => import('../atons/statistics/buoy/maintenancePlanForTheBuoyMaintenanceVessel'));

//FacilityInspection - Target
const FsinspectionsTargetList = React.lazy(() => import('../atons/facilityInspection/InspectionTarget/List'));
const FsinspectionsTargetShow = React.lazy(() => import('../atons/facilityInspection/InspectionTarget/Show'));
const FsinspectionsTargetAdd = React.lazy(() => import('../atons/facilityInspection/InspectionTarget/Add'));
const FsinspectionsTargetModify = React.lazy(() => import('../atons/facilityInspection/InspectionTarget/Modify'));

//FacilityInspection - Service
const FsinspectionsServiceList = React.lazy(() => import('../atons/facilityInspection/InspectionService/List'));
const FsinspectionsServiceShow = React.lazy(() => import('../atons/facilityInspection/InspectionService/Show'));
const FsinspectionsServiceAdd = React.lazy(() => import('../atons/facilityInspection/InspectionService/Add'));
const FsinspectionsServiceModify = React.lazy(() => import('../atons/facilityInspection/InspectionService/Modify'));

//FacilityInspection - design - Target
const FsinspectionsDesignTargetList = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignTarget/List'));
const FsinspectionsDesignTargetShow = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignTarget/Show'));
const FsinspectionsDesignTargetAdd = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignTarget/Add'));
const FsinspectionsDesignTargetModify = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignTarget/Modify'));

//FacilityInspection - design - Service
const FsinspectionsDesignServiceList = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignService/List'));
const FsinspectionsDesignServiceShow = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignService/Show'));
const FsinspectionsDesignServiceAdd = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignService/Add'));
const FsinspectionsDesignServiceModify = React.lazy(() => import('../atons/facilityInspection/ImplementationDesignService/Modify'));

//FAQ
const FAQList = React.lazy(() => import('../atons/faq/List'));
const FAQAdd = React.lazy(() => import('../atons/faq/Add'));
const FAQModify = React.lazy(() => import('../atons/faq/Modify'));

//QNA
const QNAList = React.lazy(() => import('../atons/qna/List'));
const QNAAdd = React.lazy(() => import('../atons/qna/Add'));
const QNAShow = React.lazy(() => import('../atons/qna/Show'));
const QNAAnswer = React.lazy(() => import('../atons/qna/Answer'));
const QNAModify = React.lazy(() => import('../atons/qna/Modify'));

//Notice
const NoticeList = React.lazy(() => import('../atons/notice/List'));
const NoticeAdd = React.lazy(() => import('../atons/notice/Add'));
const NoticeShow = React.lazy(() => import('../atons/notice/Show'));
const NoticeModify = React.lazy(() => import('../atons/notice/Modify'));

// SERVICE MANAGEMENT
const ServiceS124List = React.lazy(() => import('../atons/service/s124/List'));
const ServiceS124Add = React.lazy(() => import('../atons/service/s124/Add'));

const ServiceS125List = React.lazy(() => import('../atons/service/s125/List'));
const ServiceS125Add = React.lazy(() => import('../atons/service/s125/Add'));

//User Management
const UserManagementList = React.lazy(() => import('../atons/system/user/List'));
const UserManagementShow = React.lazy(() => import('../atons/system/user/Show'));

//Oweragency Management
const OweragencymanagementList = React.lazy(() => import('../atons/privateinfo/oweragency/List'));
const OweragencymanagementAdd = React.lazy(() => import('../atons/privateinfo/oweragency/Add'));
const OweragencymanagementShow = React.lazy(() => import('../atons/privateinfo/oweragency/Show'));
const OweragencymanagementModify = React.lazy(() => import('../atons/privateinfo/oweragency/Modify'));

//Consignment Management
const ConsignmentmanagementList = React.lazy(() => import('../atons/privateinfo/consignment/List'));
const ConsignmentmanagementAdd = React.lazy(() => import('../atons/privateinfo/consignment/Add'));
const ConsignmentmanagementShow = React.lazy(() => import('../atons/privateinfo/consignment/Show'));
const ConsignmentmanagementModify = React.lazy(() => import('../atons/privateinfo/consignment/Modify'));


//Code Management
const CodeManagementList = React.lazy(() => import('../atons/system/code/List'));

//Profile
const ProfileShow = React.lazy(() => import('../atons/profiles/Show'));

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
// const Register = React.lazy(() => import('../pages/account/Register'));
const Register = React.lazy(() => import('../pages/account/RegisterWizard'));
const MailAuthenticationSuccessful = React.lazy(() => import('../pages/notification/mailAuthenticationSuccessful'));
const MailAuthenticationFailed = React.lazy(() => import('../pages/notification/mailAuthenticationFailed'));
const MailReauthenticationSuccessful = React.lazy(() => import('../pages/notification/mailReauthenticationSuccessful'));
const MailReauthenticationFailed = React.lazy(() => import('../pages/notification/mailReauthenticationFailed'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

const Developing = React.lazy(() => import('../atons/developing/PageNotFoundAlt'));

//prepare
const Prepare = React.lazy(() => import('../atons/prepare'));

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// aton
// Profile
// const profile = {
//     path: '/dashboard',
//     name: 'dashboard',
//     component: Dashboard,
//     route: PrivateRoute,
// };

// const s201FileUpload = {
//     path: '/s201fileUpload',
//     name: 'S201 FileUpload',
//     component: S201FileUpload,
//     route: Route,
// };

const dashboard = {
    path: '/dashboard',
    name: 'dashboard',
    component: AtoNProfile,
    route: PrivateRoute,
};

const equipmentList = {
    path: '/equipment/list',
    name: 'Equipment List',
    component: EquipmentList,
    route: PrivateRoute,
};

const equipmentShow = {
    path: '/equipment/show',
    name: 'Equipment Show',
    component: EquipmentShow,
    route: PrivateRoute,
};

const equipmentAdd = {
    path: '/equipment/add',
    name: 'Equipment Add',
    component: EquipmentAdd,
    route: PrivateRoute,
};

const equipmentModify = {
    path: '/equipment/modify',
    name: 'Equipment Modify',
    component: EquipmentModify,
    route: PrivateRoute,
};

const structureList = {
    path: '/structure/list',
    name: 'Structure List',
    component: StructureList,
    route: PrivateRoute,
};

const structureShow = {
    path: '/structure/show',
    name: 'Structure Show',
    component: StructureShow,
    route: PrivateRoute,
};

const structureAdd = {
    path: '/structure/add',
    name: 'Structure Add',
    component: StructureAdd,
    route: PrivateRoute,
};

const structureModify = {
    path: '/structure/modify',
    name: 'Structure Modify',
    component: StructureModify,
    route: PrivateRoute,
};

const markMap = {
    path: '/mark/map',
    name: 'Mark Map',
    component: MarkMap,
    route: PrivateRoute,
};

const markList = {
    path: '/mark/list',
    name: 'Mark List',
    component: MarkList,
    route: PrivateRoute,
};

const markShow = {
    path: '/mark/show',
    name: 'Mark Show',
    component: MarkShow,
    route: PrivateRoute,
};

const markAdd = {
    path: '/mark/add',
    name: 'Mark Add',
    component: MarkAdd,
    route: PrivateRoute,
};

const markModify = {
    path: '/mark/modify',
    name: 'Mark Modify',
    component: MarkModify,
    route: PrivateRoute,
};

const proposalList = {
    path: '/proposal/list',
    name: 'Proposal List',
    component: ProposalList,
    route: PrivateRoute,
};

const proposalShow = {
    path: '/proposal/show',
    name: 'Proposal Show',
    component: ProposalShow,
    route: PrivateRoute,
};

const proposalMemberList = {
    path: '/proposal/member/list',
    name: 'ProposalMember List',
    component: ProposalMemberList,
    route: PrivateRoute,
};

const proposalMemberShow = {
    path: '/proposal/member/show',
    name: 'ProposalMember Show',
    component: ProposalMemberShow,
    route: PrivateRoute,
};

const proposalInfoMain = {
    path: '/proposal/info/home',
    name: 'ProposalInfo Main',
    component: ProposalInfoMain,
    route: PrivateRoute,
};

const proposalInfoEmergencyList = {
    path: '/proposal/info/em/list',
    name: 'ProposalInfo Emergency List',
    component: ProposalInfoEmergencyList,
    route: PrivateRoute,
};

const proposalInfoStandByList = {
    path: '/proposal/info/st/list',
    name: 'ProposalInfo StandBy List',
    component: ProposalInfoStandByList,
    route: PrivateRoute,
};

const proposalInfoStandByShow = {
    path: '/proposal/info/st/show',
    name: 'ProposalInfo StandBy Show',
    component: ProposalInfoStandByShow,
    route: PrivateRoute,
}

const proposalInfoScheduleList = {
    path: '/proposal/info/sc/list',
    name: 'ProposalInfo Schedule List',
    component: ProposalInfoScheduleList,
    route: PrivateRoute,
};

const proposalInfoScheduleShow = {
    path: '/proposal/info/sc/show',
    name: 'ProposalInfo Schedule Show',
    component: ProposalInfoScheduleShow,
    route: PrivateRoute,
}

const proposalInfoProcessList = {
    path: '/proposal/info/pr/list',
    name: 'ProposalInfo Process List',
    component: ProposalInfoProcessList,
    route: PrivateRoute,
};

const proposalInfoProcessShow = {
    path: '/proposal/info/pr/show',
    name: 'ProposalInfo Process Show',
    component: ProposalInfoProcessShow,
    route: PrivateRoute,
};

const proposalInfoCompletionList = {
    path: '/proposal/info/co/list',
    name: 'ProposalInfo Completion List',
    component: ProposalInfoCompletionList,
    route: PrivateRoute,
};

const proposalInfoCompletionShow = {
    path: '/proposal/info/co/show',
    name: 'ProposalInfo Completion Show',
    component: ProposalInfoCompletionShow,
    route: PrivateRoute,
}

const proposalInfoEntireList = {
    path: '/proposal/info/en/list',
    name: 'ProposalInfo Entire List',
    component: ProposalInfoEntireList,
    route: PrivateRoute,
};

const proposalInfoEntireShow = {
    path: '/proposal/info/en/show',
    name: 'ProposalInfo Entire Show',
    component: ProposalInfoEntireShow,
    route: PrivateRoute,
};

const operationList = {
    path: '/operation/manager/list',
    name: 'Operation List',
    component: OperationList,
    route: PrivateRoute,
};

const operationShow = {
    path: '/operation/manager/show',
    name: 'Operation Show',
    component: OperationShow,
    route: PrivateRoute,
};

const operationAdd = {
    path: '/operation/manager/add',
    name: 'Operation Add',
    component: OperationAdd,
    route: PrivateRoute,
};

const operationModify = {
    path: '/operation/manager/modify',
    name: 'Operation Modify',
    component: OperationModify,
    route: PrivateRoute,
};

const operationCompleteList = {
    path: '/operation/complete/list',
    name: 'Operation List',
    component: OperationCompleteList,
    route: PrivateRoute,
};

const operationCompleteShow = {
    path: '/operation/complete/show',
    name: 'Operation Show',
    component: OperationCompleteShow,
    route: PrivateRoute,
};

const operationStatistics = {
    path: '/operation/statistics',
    name: 'Operation Statistics',
    component: OperationStatistics,
    route: PrivateRoute,
};

const operationPlanList = {
    path: '/operation/plan/list',
    name: 'Operation Plan List',
    component: OperationPlanList,
    route: PrivateRoute,
};

const operationPlanShow = {
    path: '/operation/plan/show',
    name: 'Operation Plan Show',
    component: OperationPlanShow,
    route: PrivateRoute,
};

const operationPlanAdd = {
    path: '/operation/plan/add',
    name: 'Operation Plan Add',
    component: OperationPlanAdd,
    route: PrivateRoute,
};

const operationPlanModify = {
    path: '/operation/plan/modify',
    name: 'Operation Plan Modify',
    component: OperationPlanModify,
    route: PrivateRoute,
};

const maintenanceList = {
    path: '/maintenance/manager/list',
    name: 'Maintenance List',
    component: MaintenanceList,
    route: PrivateRoute,
};

const maintenanceShow = {
    path: '/maintenance/manager/show',
    name: 'Maintenance Show',
    component: MaintenanceShow,
    route: PrivateRoute,
};

const maintenanceAdd = {
    path: '/maintenance/manager/add',
    name: 'Maintenance Add',
    component: MaintenanceAdd,
    route: PrivateRoute,
};

const maintenanceModify = {
    path: '/maintenance/manager/modify',
    name: 'Maintenance Modify',
    component: MaintenanceModify,
    route: PrivateRoute,
};

const maintenanceCompleteList = {
    path: '/maintenance/complete/list',
    name: 'Maintenance List',
    component: MaintenanceCompleteList,
    route: PrivateRoute,
};

const maintenanceCompleteShow = {
    path: '/maintenance/complete/show',
    name: 'Maintenance Show',
    component: MaintenanceCompleteShow,
    route: PrivateRoute,
};

const maintenanceStatistics = {
    path: '/maintenance/statistics',
    name: 'Maintenance Statistics',
    component: MaintenanceStatistics,
    route: PrivateRoute,
};

const maintenancePlanList = {
    path: '/maintenance/plan/list',
    name: 'Maintenance Plan List',
    component: MaintenancePlanList,
    route: PrivateRoute,
};

const maintenancePlanEdit = {
    path: '/maintenance/plan/edit',
    name: 'Maintenance Plan Edit',
    component: MaintenancePlanEdit,
    route: PrivateRoute,
};

const repairequipmentList = {
    path: '/repair/equipment/list',
    name: 'Repair Equipment List',
    component: RepairEquipmentList,
    route: PrivateRoute,
};

const repairequipmentModify = {
    path: '/repair/equipment/modify',
    name: 'Repair Equipment Modify',
    component: RepairEquipmentModify,
    route: PrivateRoute,
}

const repairequipmentShow = {
    path: '/repair/equipment/show',
    name: 'Repair Equipment Show',
    component: RepairEquipmentShow,
    route: PrivateRoute,
}

const repairstructureList = {
    path: '/repair/structure/list',
    name: 'Repair Structure List',
    component: RepairStructureList,
    route: PrivateRoute,
}

const repairstructureModify = {
    path: '/repair/structure/modify',
    name: 'Repair Structure Modify',
    component: RepairStructureModify,
    route: PrivateRoute,
}

const repairstructureShow = {
    path: '/repair/structure/show',
    name: 'Repair Structure Show',
    component: RepairStructureShow,
    route: PrivateRoute,
}

const accidentList = {
    path: '/accident/list',
    name: 'Accident List',
    component: AccidentList,
    route: PrivateRoute,
}

const accidentAdd = {
    path: '/accident/add',
    name: 'Accident Add',
    component: AccidentAdd,
    route: PrivateRoute,
}

const accidentShow = {
    path: '/accident/show',
    name: 'Accident Show',
    component: AccidentShow,
    route: PrivateRoute,
}

const accidentModify = {
    path: '/accident/modify',
    name: 'Accident Modify',
    component: AccidentModify,
    route: PrivateRoute,
};

const constructionList = {
    path: '/privateinfo/construction/list',
    name: 'Construction List',
    component: ConstructionList,
    route: PrivateRoute,
};

const constructionAdd = {
    path: '/privateinfo/construction/add',
    name: 'Construction Add',
    component: ConstructionAdd,
    route: PrivateRoute,
};

const constructionShow = {
    path: '/privateinfo/construction/show',
    name: 'Construction Show',
    component: ConstructionShow,
    route: PrivateRoute,
};

const constructionModify = {
    path: '/privateinfo/construction/modify',
    name: 'Construction Modify',
    component: ConstructionModify,
    route: PrivateRoute,
};

const oweragencymanagementList = {
    path: '/privateinfo/oweragency/list',
    name: 'Oweragencymanagement List',
    component: OweragencymanagementList,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const oweragencymanagementAdd = {
    path: '/privateinfo/oweragency/add',
    name: 'Oweragencymanagement Add',
    component: OweragencymanagementAdd,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const oweragencymanagementShow = {
    path: '/privateinfo/oweragency/show',
    name: 'Oweragencymanagement Show',
    component: OweragencymanagementShow,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const oweragencymanagementModify = {
    path: '/privateinfo/oweragency/modify',
    name: 'Oweragencymanagement Modify',
    component: OweragencymanagementModify,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const consignmentmanagementList = {
    path: '/privateinfo/consignment/list',
    name: 'ConsignmentManagement List',
    component: ConsignmentmanagementList,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자', '위탁관리원'],
};

const consignmentmanagementAdd = {
    path: '/privateinfo/consignment/add',
    name: 'ConsignmentManagement Add',
    component: ConsignmentmanagementAdd,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const consignmentmanagementShow = {
    path: '/privateinfo/consignment/show',
    name: 'Consignmentmanagement Show',
    component: ConsignmentmanagementShow,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자', '위탁관리원'],
};

const consignmentmanagementModify = {
    path: '/privateinfo/consignment/modify',
    name: 'Consignmentmanagement Modify',
    component: ConsignmentmanagementModify,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '해양수산부', '사설담당자', '국유담당자', '부표관리자', '공사담당자'],
};

const statisticsEquipmentSuppliesManagementStatus = {
    path: '/statistics/equipmentsuppliesmanagementstatus/list',
    name: 'Statistics Equipment Supplies Management Status',
    component: StatisticsEquipmentSuppliesManagementStatus,
    route: PrivateRoute,
};

const statisticsEquipmentInstallationAndOperationStatus = {
    path: '/statistics/equipmentinstallationandoperationstatus/list',
    name: 'Statistics Equipment Installation And Operation Status',
    component: StatisticsEquipmentInstallationAndOperationStatus,
    route: PrivateRoute,
}

const statisticsEquipmentStatusByMark = {
    path: '/statistics/equipmentstatusbymark/list',
    name: 'Statistics Equipment Status By Mark',
    component: StatisticsEquipmentStatusByMark,
    route: PrivateRoute,
}

const statisticsStatusOfInspectionTargetEquipmentAndSupplies = {
    path: '/statistics/inspectiontargetequipmentandsupplies/list',
    name: 'Statistics Status Of Inspection Target Equipment And Supplies',
    component: StatisticsStatusOfInspectionTargetEquipmentAndSupplies,
    route: PrivateRoute,
}

const statisticsMarkGeneralStatusList = {
    path: '/statistics/markgeneralstatus/list',
    name: 'Statistics Mark GeneralStatus List',
    component: StatisticsMarkGeneralStatusList,
    route: PrivateRoute,
};

const statisticsMarkClassStatusList = {
    path: '/statistics/classstatus/list',
    name: 'Statistics Mark ClassStatus List',
    component: StatisticsMarkClassStatusList,
    route: PrivateRoute,
};

const statisticsMarkPurposeStatusList = {
    path: '/statistics/markpurposestatus/list',
    name: 'Statistics Mark Purpose StatusList',
    component: StatisticsMarkPurposeStatusList,
    route: PrivateRoute,
};

const statisticsMarkSpecificPurposeStatusList1 = {
    path: '/statistics/markspecificpurposestatus1/list',
    name: 'Statistics Mark Specific Purpose Status List1',
    component: StatisticsMarkSpecificPurposeStatusList1,
    route: PrivateRoute,
};

const statisticsMarkSpecificPurposeStatusList2 = {
    path: '/statistics/markspecificpurposestatus2/list',
    name: 'Statistics Mark Specific Purpose Status List2',
    component: StatisticsMarkSpecificPurposeStatusList2,
    route: PrivateRoute,
};

const statisticsStatusOfPublicAndPrivateMark = {
    path: '/statistics/statusofpublicandprivatemark/list',
    name: 'Statistics Status Of Public And Private Mark',
    component: StatisticsStatusOfPublicAndPrivateMark,
    route: PrivateRoute,
};

const statisticsStatusOfPrivateMark = { 
    path: '/statistics/statusofprivatemark/list',
    name: 'Statistics Status Of Private Mark',
    component: StatisticsStatusOfPrivateMark,
    route: PrivateRoute,
}

const statisticsStatusOfFacilityAndRegistrationOutsourcedManagement = {
    path: '/statistics/Statusoffacilityandregistrationoutsourcedmanagement/list',
    name: 'Statistics Status Of Facility And Registration Outsourced Management',
    component: StatisticsStatusOfFacilityAndRegistrationOutsourcedManagement,
    route: PrivateRoute,
};

const statisticsCurrentStatusOfOffshoreBuoyDeployment = {
    path: '/statistics/currentstatusofoffshorebuoydeployment/list',
    name: 'Statistics Current Status Of Offshore Buoy Deployment',
    component: StatisticsCurrentStatusOfOffshoreBuoyDeployment,
    route: PrivateRoute,
};

const statisticsInventoryStatusOfBuoy = {
    path: '/statistics/inventorystatusofbuoy/list',
    name: 'Statistics Inventory Status Of Buoy',
    component: StatisticsInventoryStatusOfBuoy,
    route: PrivateRoute,
}

const statisticsInstallationAndInventoryStatusOfBuoy = {
    path: '/statistics/installationandinventorystatusofbuoy/list',
    name: 'Statistics Installation And Inventory Status Of Buoy',
    component: StatisticsInstallationAndInventoryStatusOfBuoy,
    route: PrivateRoute,
};

const statisticsAnnualBuoyStatus = {
    path: '/statistics/annualbuoystatus/list',
    name: 'Statistics Annual Buoy Status',
    component: StatisticsAnnualBuoyStatus,
    route: PrivateRoute,
}

const statisticsAnnualBuoyMaintenanceStatus = {
    path: '/statistics/annualbuoymaintenancestatus/list',
    name: 'Statistics Annual Buoy Maintenance Status',
    component: StatisticsAnnualBuoyMaintenanceStatus,
    route: PrivateRoute,
}

const statisticsMonthlyBuoyMaintenanceShipStatus = {
    path: '/statistics/monthlybuoymaintenanceshipstatus/list',
    name: 'Statistics Monthly Buoy Maintenance Ship Status',
    component: StatisticsMonthlyBuoyMaintenanceShipStatus,
    route: PrivateRoute,
};

const statisticsDetailedStatusOfAdditionalBuoyWork = {
    path: '/statistics/detailedstatusofadditionalwork/list',
    name: 'Statistics Detailed Status Of Additional Buoy Work',
    component: StatisticsDetailedStatusOfAdditionalBuoyWork,
    route: PrivateRoute,
};

const statisticsMaintenancePlanForTheBuoyMaintenanceVessel = {
    path: '/statistics/maintenanceplanforthebuoymaintenancevessel/list',
    name: 'Statistics Maintenance Plan For The Buoy Maintenance Vessel',
    component: StatisticsMaintenancePlanForTheBuoyMaintenanceVessel,
    route: PrivateRoute,
};

const fsinspectionsTargetList = {
    path: '/fsinspections/inspectiontarget/list',
    name: 'Fsinspections Target List',
    component: FsinspectionsTargetList,
    route: PrivateRoute,
};

const fsinspectionsTargetShow = {
    path: '/fsinspections/inspectiontarget/show',
    name: 'Fsinspections Target Show',
    component: FsinspectionsTargetShow,
    route: PrivateRoute,
};

const fsinspectionsTargetAdd = {
    path: '/fsinspections/inspectiontarget/add',
    name: 'Fsinspections Target Add',
    component: FsinspectionsTargetAdd,
    route: PrivateRoute,
};

const fsinspectionsTargetModify = {
    path: '/fsinspections/inspectiontarget/modify',
    name: 'Fsinspections Target Modify',
    component: FsinspectionsTargetModify,
    route: PrivateRoute,
}

const fsinspectionsServiceList = {
    path: '/fsinspections/inspectionservice/list',
    name: 'Fsinspections Service List',
    component: FsinspectionsServiceList,
    route: PrivateRoute,
};

const fsinspectionsServiceShow = {
    path: '/fsinspections/inspectionservice/show',
    name: 'Fsinspections Service Show',
    component: FsinspectionsServiceShow,
    route: PrivateRoute,
};

const fsinspectionsServiceAdd = {
    path: '/fsinspections/inspectionservice/add',
    name: 'Fsinspections Service Add',
    component: FsinspectionsServiceAdd,
    route: PrivateRoute,
};

const fsinspectionsServiceModify = {
    path: '/fsinspections/inspectionservice/modify',
    name: 'Fsinspections Service Modify',
    component: FsinspectionsServiceModify,
    route: PrivateRoute,
};

const fsinspectionsDesignTargetList = {
    path: '/fsinspections/designtarget/list',
    name: 'Fsinspections Design Target List',
    component: FsinspectionsDesignTargetList,
    route: PrivateRoute,
};

const fsinspectionsDesignTargetShow = {
    path: '/fsinspections/designtarget/show',
    name: 'Fsinspections Design Target Show',
    component: FsinspectionsDesignTargetShow,
    route: PrivateRoute,
};

const fsinspectionsDesignTargetAdd = {
    path: '/fsinspections/designtarget/add',
    name: 'Fsinspections Design Target Add',
    component: FsinspectionsDesignTargetAdd,
    route: PrivateRoute,
};

const fsinspectionsDesignTargetModify = {
    path: '/fsinspections/designtarget/modify',
    name: 'Fsinspections Design Target Modify',
    component: FsinspectionsDesignTargetModify,
    route: PrivateRoute,
};

const fsinspectionsDesignServiceList = {
    path: '/fsinspections/designservice/list',
    name: 'Fsinspections Design Service List',
    component: FsinspectionsDesignServiceList,
    route: PrivateRoute,
};

const fsinspectionsDesignServiceShow = {
    path: '/fsinspections/designservice/show',
    name: 'Fsinspections Design Service Show',
    component: FsinspectionsDesignServiceShow,
    route: PrivateRoute,
};

const fsinspectionsDesignServiceAdd = {
    path: '/fsinspections/designservice/add',
    name: 'Fsinspections Design Service Add',
    component: FsinspectionsDesignServiceAdd,
    route: PrivateRoute,
};

const fsinspectionsDesignServiceModify = {
    path: '/fsinspections/designservice/modify',
    name: 'Fsinspections Design Service Modify',
    component: FsinspectionsDesignServiceModify,
    route: PrivateRoute,
};

const faqList = {
    path: '/information/faq/list',
    name: 'FAQ List',
    component: FAQList,
    route: PrivateRoute,
};

const faqAdd = {
    path: '/information/faq/add',
    name: 'FAQ Add',
    component: FAQAdd,
    route: PrivateRoute,
};

const faqModify = {
    path: '/information/faq/modify',
    name: 'FAQ Modify',
    component: FAQModify,
    route: PrivateRoute,
};

const qnaList = {
    path: '/information/qna/list',
    name: 'QNA List',
    component: QNAList,
    route: PrivateRoute,
};

const qnaAdd = {
    path: '/information/qna/add',
    name: 'QNA Add',
    component: QNAAdd,
    route: PrivateRoute,
};

const qnaShow = {
    path: '/information/qna/show',
    name: 'QNA Show',
    component: QNAShow,
    route: PrivateRoute,
};

const qnaAnswer = {
    path: '/information/qna/answer',
    name: 'QNA Answer',
    component: QNAAnswer,
    route: PrivateRoute,
};

const qnaModify = {
    path: '/information/qna/modify',
    name: 'QNA Modify',
    component: QNAModify,
    route: PrivateRoute,
};

const noticeList = {
    path: '/information/notice/list',
    name: 'Notice List',
    component: NoticeList,
    route: PrivateRoute,
};

const noticeAdd = {
    path: '/information/notice/add',
    name: 'Notice Add',
    component: NoticeAdd,
    route: PrivateRoute,
};

const noticeShow = {
    path: '/information/notice/show',
    name: 'Notice Show',
    component: NoticeShow,
    route: PrivateRoute,
};

const noticeModify = {
    path: '/information/notice/modify',
    name: 'Notice Modify',
    component: NoticeModify,
    route: PrivateRoute,
};

const serviceS124List = {
    path: '/service/s124/list',
    name: 'S124 List',
    component: ServiceS124List,
    route: PrivateRoute,
};

const serviceS124Add = {
    path: '/service/s124/add',
    name: 'S124 Add',
    component: ServiceS124Add,
    route: PrivateRoute,
};

const serviceS125List = {
    path: '/service/s125/list',
    name: 'S125 List',
    component: ServiceS125List,
    route: PrivateRoute,
};

const setviceS125Add = {
    path: '/service/s125/add',
    name: 'S125 Add',
    component: ServiceS125Add,
    route: PrivateRoute,
};

const usermanagementList = {
    path: '/system/user/list',
    name: 'UserManagement List',
    component: UserManagementList,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '국유담당자', '사설담당자', '부표관리자', '항로표지관리원', '해양수산부'],
};

const userManagementShow = {
    path: '/system/user/show',
    name: 'UserManagement Show',
    component: UserManagementShow,
    route: PrivateRoute,
    roles: ['시스템관리자','지방청관리자', '국유담당자', '사설담당자', '부표관리자', '항로표지관리원', '해양수산부'],
};

const codemanagementList = {
    path: '/system/code/list',
    name: 'CodeManagement List',
    component: CodeManagementList,
    route: PrivateRoute,
    roles: ['시스템관리자', '해양수산부'],
};

const profileShow = {
    path: '/profile/show',
    name: 'Profile Show',
    component: ProfileShow,
    route: PrivateRoute,
};

const developing = {
    path: '/developing',
    name: 'Notice Show',
    component: Developing,
    route: PrivateRoute,
};

const prepare = {
    path: '/prepare',
    name: 'Prepare',
    component: Prepare,
    route: PrivateRoute,
};

const appRoutes = [
    dashboard,
    equipmentList,
    equipmentShow,
    equipmentAdd,
    equipmentModify,
    structureList,
    structureShow,
    structureAdd,
    structureModify,
    markMap,
    markList,
    markShow,
    markAdd,
    markModify,
    proposalList,
    proposalShow,
    proposalMemberList,
    proposalMemberShow,
    proposalInfoMain,
    proposalInfoEmergencyList,
    proposalInfoStandByList,
    proposalInfoStandByShow,
    proposalInfoScheduleList,
    proposalInfoScheduleShow,
    proposalInfoProcessList,
    proposalInfoProcessShow,
    proposalInfoCompletionList,
    proposalInfoCompletionShow,
    proposalInfoEntireList,
    proposalInfoEntireShow,
    operationList,
    operationShow,
    operationAdd,
    operationModify,
    operationCompleteList,
    operationCompleteShow,
    operationStatistics,
    operationPlanList,
    operationPlanShow,
    operationPlanAdd,
    operationPlanModify,
    maintenanceList,
    maintenanceShow,
    maintenanceAdd,
    maintenanceModify,
    maintenanceCompleteList,
    maintenanceCompleteShow,
    maintenanceStatistics,
    maintenancePlanList,
    maintenancePlanEdit,
    repairequipmentList,
    repairequipmentModify,
    repairequipmentShow,
    repairstructureList,
    repairstructureModify,
    repairstructureShow,
    accidentList,
    accidentAdd,
    accidentShow,
    accidentModify,
    constructionList,
    constructionAdd,
    constructionShow,
    constructionModify,
    oweragencymanagementList,
    oweragencymanagementAdd,
    oweragencymanagementShow,
    oweragencymanagementModify,
    consignmentmanagementList,
    consignmentmanagementAdd,
    consignmentmanagementShow,
    consignmentmanagementModify,
    statisticsEquipmentSuppliesManagementStatus,
    statisticsEquipmentInstallationAndOperationStatus,
    statisticsEquipmentStatusByMark,
    statisticsStatusOfInspectionTargetEquipmentAndSupplies,
    statisticsMarkGeneralStatusList,
    statisticsMarkClassStatusList,
    statisticsMarkPurposeStatusList,
    statisticsMarkSpecificPurposeStatusList1,
    statisticsMarkSpecificPurposeStatusList2,
    statisticsStatusOfPublicAndPrivateMark,
    statisticsStatusOfPrivateMark,
    statisticsStatusOfFacilityAndRegistrationOutsourcedManagement,
    statisticsCurrentStatusOfOffshoreBuoyDeployment,
    statisticsInventoryStatusOfBuoy,
    statisticsInstallationAndInventoryStatusOfBuoy,
    statisticsAnnualBuoyStatus,
    statisticsAnnualBuoyMaintenanceStatus,
    statisticsMonthlyBuoyMaintenanceShipStatus,
    statisticsDetailedStatusOfAdditionalBuoyWork,
    statisticsMaintenancePlanForTheBuoyMaintenanceVessel,
    fsinspectionsTargetList,
    fsinspectionsTargetShow,
    fsinspectionsTargetAdd,
    fsinspectionsTargetModify,
    fsinspectionsServiceList,
    fsinspectionsServiceShow,
    fsinspectionsServiceAdd,
    fsinspectionsServiceModify,
    fsinspectionsDesignTargetList,
    fsinspectionsDesignTargetShow,
    fsinspectionsDesignTargetAdd,
    fsinspectionsDesignTargetModify,
    fsinspectionsDesignServiceList,
    fsinspectionsDesignServiceShow,
    fsinspectionsDesignServiceAdd,
    fsinspectionsDesignServiceModify,
    faqList,
    faqAdd,
    faqModify,
    qnaList,
    qnaAdd,
    qnaShow,
    qnaAnswer,
    qnaModify,
    noticeList,
    noticeAdd,
    noticeShow,
    noticeModify,
    serviceS124List,
    serviceS124Add,
    serviceS125List,
    setviceS125Add,
    usermanagementList,
    userManagementShow,
    codemanagementList,
    profileShow,
    developing,
    prepare,
    // s201FileUpload,
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// auth
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/account/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/mail/successful',
        name: 'MailAuthentication Successful',
        component: MailAuthenticationSuccessful,
        route: Route,
    },
    {
        path: '/mail/failed',
        name: 'MailAuthentication Failed',
        component: MailAuthenticationFailed,
        route: Route,
    },
    {
        path: '/remail/successful',
        name: 'MailReauthentication Successful',
        component: MailReauthenticationSuccessful,
        route: Route,
    },
    {
        path: '/remail/failed',
        name: 'MailReauthentication Failed',
        component: MailReauthenticationFailed,
        route: Route,
    },
    {
        path: '/account/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route, 
    },
    {
        path: '/account/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/account/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/s201fileUpload',
        name: 'S201 FileUpload',
        component: S201FileUpload,
        route: Route,
    },
];

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
